<template>
  <div style="max-width: 100%; overflow-x: hidden">
    <NavBarComp />

    <section>
      <!-- <img
        class="banner-img"
        src="./../assets/Consltunt panel_web_design/Scroll Group 17.png"
        alt="company logo banner"
      /> -->
      <div class="width-100 overflow-hidden d-flex align-items-center justify-content-center">
        <img src="@/assets/Consltunt panel_web_design/Scroll Group 17.png" alt="Header Section Banner" class="width-100"
          style="min-width: 90rem;" />
        <!-- <div
          style="position: absolute; display: flex; align-items: center; width: 100%; justify-content: center; z-index: 1;"
        >
          <img
            class="logo"
            style="width: 15%; position: absolute; margin-top:25%;  "
            src="./../assets/Consltunt panel_web_design/IT_MATLAB_IB.png"
            alt=""
          />
        </div>

        <div class="marquee-container">
          <div
            class="marquee marquee-right-to-left"
            behavior="scroll"
            direction="left"
          >
            IT MEANS IB <span style="margin-left: 25%;">IT MEANS IB</span>
          </div>
          <div
            class="marquee marquee-right-to-left"
            behavior="scroll"
            direction="left"
          >
            IT એટલી IB <span style="margin-left: 25%;">IT એટલી IB </span>
          </div>
          <div
            class="marquee marquee-right-to-left"
            behavior="scroll"
            direction="left"
          >
            IT मतलब IB <span style="margin-left: 25%;">IT मतलब IB </span>
          </div>
        </div>

        <div class="marquee-container">
          <marquee
            style="color: white; font-size: 1.9rem;"
            class=" "
            behavior="scroll"
            direction="right"
            >IT MEANS IB</marquee
          >
          <marquee
            style="color: white; font-size: 1.9rem;"
            class=" "
            behavior="scroll"
            direction="right"
            >IT એટલી IB</marquee
          >
          <marquee
            style="color: white; font-size: 1.9rem;"
            class=" "
            behavior="scroll"
            direction="right"
            >IT मतलब IB</marquee
          >
        </div>

        <div class="marquee-container">
          <div
            class="marquee marquee-right-to-left"
            behavior="scroll"
            direction="left"
          >
            IT MEANS IB
          </div>
          <div
            class="marquee marquee-right-to-left"
            behavior="scroll"
            direction="left"
          >
            IT એટલી IB
          </div>
          <div
            class="marquee marquee-right-to-left"
            behavior="scroll"
            direction="left"
          >
            IT मतलब IB
          </div>
        </div>

        <div class="marquee-container">
          <marquee
            style="color: white; font-size: 1.9rem;"
            class=" "
            behavior="scroll"
            direction="right"
            >IT MEANS IB</marquee
          >
          <marquee
            style="color: white; font-size: 1.9rem;"
            class=" "
            behavior="scroll"
            direction="right"
            >IT એટલી IB</marquee
          >
          <marquee
            style="color: white; font-size: 1.9rem;"
            class=" "
            behavior="scroll"
            direction="right"
            >IT मतलब IB</marquee
          >
        </div>

        <div class="marquee-container">
          <div
            class="marquee marquee-right-to-left"
            behavior="scroll"
            direction="left"
          >
            IT MEANS IB
          </div>
          <div
            class="marquee marquee-right-to-left"
            behavior="scroll"
            direction="left"
          >
            IT એટલી IB
          </div>
          <div
            class="marquee marquee-right-to-left"
            behavior="scroll"
            direction="left"
          >
            IT मतलब IB
          </div>
        </div>

        <div class="marquee-container">
          <marquee
            style="color: white; font-size: 1.9rem;"
            class=" "
            behavior="scroll"
            direction="right"
            >IT MEANS IB</marquee
          >
          <marquee
            style="color: white; font-size: 1.9rem;"
            class=" "
            behavior="scroll"
            direction="right"
            >IT એટલી IB</marquee
          >
          <marquee
            style="color: white; font-size: 1.9rem;"
            class=" "
            behavior="scroll"
            direction="right"
            >IT मतलब IB</marquee
          >
        </div> -->
      </div>

      <!-- <div
        style="height: 80px; background-color: #0c2d61; margin-top: -2px; border-top: transparent; margin-bottom: 80px; border-bottom: 20px solid #fff; border-bottom-left-radius: 100%; border-bottom-right-radius: 100%; display: flex; align-items: center; justify-content: center;"
      ></div> -->
    </section>

    <!-- About Infinity Brains Section  -->

    <AboutInfinityBrainsVue />

    <section class="introduction mt-5">
      <div class="intro-title">
        <h3 class="">Introduction</h3>
        <p>Consultant Panel</p>
      </div>
      <div class="row px-4 px-md-5">
        <div class="col-xl-6 col-lg-12  col-md-12 pt-5 pb-5 pl-5 ml-5 col-sm-12 d-flex justify-content-center">
          <img class="" style=" max-height: 100%; object-fit: cover;"
            src="./../assets/Consltunt panel_web_design/it-was-pleasure-doing-business-with-you-colleague.png" alt="" />
        </div>
        <div class="col-xl-6 col-lg-12  col-md-12 col-sm-12 ml-5 "
          style="  display: flex;text-align: left;  align-items: center;">
          <div
            style="font-size: 1.2em; font-weight: 400; color: #616161; text-align: justify; padding: 2%; line-height: 1.6;">
            <strong>Welcome to Consultant Panel – Your Ultimate HR Recruitment Solution</strong>
            <br>
            <br>
            <span style="font-weight: 600;">Looking to streamline your recruitment process?</span> Consultant Panel is
            the go-to platform designed exclusively for HR consultants, making talent acquisition effortless and
            efficient.
            <br>
            <br>
            Our innovative recruitment platform simplifies the hiring process by providing advanced features like
            <span style="font-weight: 600;">Consultant ID Creation</span> and <span style="font-weight: 600;">Job
              Vacancy Management</span>, helping you save time, enhance productivity, and improve the candidate
            experience.
            <br>
            <br>
            With a strong commitment to HR technology and recruitment innovation, we go beyond just a platform—<span
              style="font-weight: 600;">we become your strategic partner</span> in achieving recruitment excellence.
            <br>
            <br>
            <span style="font-weight: 600;">Join Consultant Panel today</span> and experience a smarter, faster, and
            more effective way to recruit top talent.
          </div>

        </div>
      </div>
    </section>

    <section class="banner-signup">
      <div style="
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        ">
        <h1 style="
            font-weight: 500;
            color: rgba(0, 0, 0, 0.774);
            margin-bottom: 2%;
            position: relative;
            text-align: center;
          ">
          CONSULTANT PANEL IS FREE FOREVER
          <span style="position: absolute; top: -43px; right: -60px">
            <img style="width: 75%" src="./../assets/Consltunt panel_web_design/Group 6221.png" alt="" />
          </span>
        </h1>
        <button style="
            padding: 15px 58px;
            font-size: 1.3rem;
            background-color: white;
            border: none;
            border-radius: 7px;
            font-weight: 500;
          " @click="signup">
          Sign Up
        </button>
      </div>
    </section>

    <section>
      <div class="banner-feature">
        <span class="f"><img src="./../assets/Consltunt panel_web_design/chart-square.png" alt="" /><br />
          <h3 style="margin-top: 10%; font-size: 1.5rem; font-weight: 500">
            Data Analysis
          </h3>
        </span>
        <span class="f"><img src="./../assets/Consltunt panel_web_design/user-tag.png" alt="" /><br />
          <h3 style="margin-top: 10%; font-size: 1.5rem; font-weight: 500">
            Consultant ID Creation
          </h3>
        </span>
        <span class="f"><img src="./../assets/Consltunt panel_web_design/personalcard.png" alt="" /><br />
          <h3 style="margin-top: 10%; font-size: 1.5rem; font-weight: 500">
            Job Vacancy Management
          </h3>
        </span>
        <span class="f"><img src="./../assets/Consltunt panel_web_design/people.png" alt="" /><br />
          <h3 style="margin-top: 10%; font-size: 1.5rem; font-weight: 500">
            User Empowerment
          </h3>
        </span>
      </div>
    </section>

    <section>
      <div class="banner-company_dis">
        <h1 style="color: black; text-shadow: 0px 1px 1px; margin-bottom: 30px;">
          Discover the Consultant Panel
        </h1>
        <h4 style="margin-bottom: 10px">
          <span style="font-weight: 500; text-shadow: 0px 1px 1px;">Time Efficiency:</span>
          Streamline your recruitment process and save valuable time with Consultant Panel's automated administrative
          features.
        </h4>
        <h4>
          <span style="font-weight: 500; text-shadow: 0px 1px 1px;">Boosted Productivity:</span>
          Concentrate on strategic activities and enhance productivity using Consultant Panel's intuitive
          functionalities.
        </h4>
        <h4>
          <span style="font-weight: 500; text-shadow: 0px 1px 1px;">Enhanced Candidate Experience:</span>
          Offer candidates a seamless experience with real-time updates and detailed job descriptions, improving
          engagement and loyalty.
        </h4>
        <h4>
          <span style="font-weight: 500; text-shadow: 0px 1px 1px;">Cost Effectiveness:</span>
          Achieve maximum results while reducing recruitment costs through Consultant Panel's optimized processes.
        </h4>
        <h4>
          <span style="font-weight: 500; text-shadow: 0px 1px 1px;">Data Protection:</span>
          Rest assured that your recruitment data is secure with Consultant Panel's robust security measures.
        </h4>
        <h4>
          <span style="font-weight: 500; text-shadow: 0px 1px 1px;">Strategic Insights:</span>
          Make informed decisions and strategic plans using valuable insights and analytics from Consultant Panel.
        </h4>
        <h4>
          <span style="font-weight: 500; text-shadow: 0px 1px 1px;">Competitive Edge:</span>
          Stand out in the market and attract top talent with Consultant Panel's innovative recruitment solutions.
        </h4>
        <h4>
          <span style="font-weight: 500; text-shadow: 0px 1px 1px;">Flexibility and Personalization:</span>
          Customize your dashboard and workflows to meet your specific preferences and needs, maximizing flexibility and
          efficiency.
        </h4>
        <h4>
          <span style="font-weight: 500; text-shadow: 0px 1px 1px;">Professional Growth:</span>
          Advance your skills and professional development with access to innovative tools and best practices provided
          by Consultant Panel.
        </h4>
        <h4>
          <span style="font-weight: 500; text-shadow: 0px 1px 1px;">Organizational Success:</span>
          Propel organizational success and growth with Consultant Panel's streamlined processes and empowered
          consultants.
        </h4>
        <h4>
          <span style="font-weight: 500; text-shadow: 0px 1px 1px;">Consultant ID Generation:</span><br />
          Effortlessly create your Consultant ID with Consultant Panel, giving you access to a personalized dashboard
          tailored to your recruitment needs. Say goodbye to complex administrative tasks and hello to efficient
          management of job vacancies and candidate profiles. With enhanced security measures, rest easy knowing your
          sensitive recruitment data is always protected. Customize your dashboard to optimize efficiency and workflow
          management, putting you in control of your recruitment journey like never before.
        </h4>
        <h4>
          <span style="font-weight: 500; text-shadow: 0px 1px 1px;">Job Vacancy Management:</span><br />
          Simplify the job vacancy management process with Consultant Panel. Post, manage, and update job vacancies
          effortlessly, removing the usual administrative burdens of recruitment. Our user-friendly interface allows you
          to include comprehensive job descriptions, specific qualifications, and application deadlines easily. Navigate
          the recruitment process efficiently, saving valuable time and resources while keeping candidates updated in
          real-time.
        </h4>
        <h4>
          <span style="font-weight: 500; text-shadow: 0px 1px 1px;">User Empowerment:</span><br />
          Experience a new level of empowerment with the Consultant Panel. Our user-friendly platform boosts efficiency,
          allowing you to focus on strategic tasks that drive organizational success. As your strategic recruitment
          partner, we are committed to continuous innovation and improvement, ensuring you have access to cutting-edge
          tools and features. Consultant Panel revolutionizes the recruitment landscape, making the process not just
          efficient but also empowering for consultants and employers alike.
        </h4>
      </div>

    </section>

    <section style="margin-top: 100px">
      <img class="smallImg" style="width: 100%" src="./../assets/Consltunt panel_web_design/promotion-banner.png"
        alt="" />
    </section>

    <section style="margin-top: 100px; text-align: center">
      <h2 style="
          font-size: 2.5rem;
          line-height: normal;
          font-weight: 500;
          margin-bottom: 0;
          color: black;
        ">
        Checkout Out Other <span style="color: rgb(66, 66, 155)">Products</span>
      </h2>
      <h3 style="
          font-size: 1.5rem;
          font-weight: 600;
          color: rgb(119, 117, 117);
          top: 0;
          margin: 0;
        ">
        Discover More Software Solution To enhance Your Digital Experience
      </h3>
    </section>

    <section class="largeWindow" style="margin-top: 100px; padding: 0px 10px;  margin-bottom: 50px">
      <div class="row ">
        <!-- 1st row -->
        <div class="col-md-6 col-sm-12  mb-5" id="left-product">
          <div class="row h-100" style="display: flex; align-items: center; justify-content: end">
            <!-- Content Side -->
            <div class="col-md-8 col-sm-12 p-4 d-flex text-right h-100" style="text-align: right">
              <div class="content-side" @click="
                freeforever(
                  'https://play.google.com/store/apps/details?id=com.redrestro&hl=en&gl=US'
                )
                ">
                <h4 class="title">RED RESTRO</h4>
                <p class="description d-flex text-right">
                  Red Restro is a revolutionary Customer Relationship Management
                  (CRM) application meticulously designed to cater to the
                  diverse needs of businesses in today's dynamic market
                  landscape. At its core, Red Restro serves as a comprehensive
                  solution aimed at optimizing business processes and enhancing
                  service.
                </p>

                <div class="price-div">
                  <div class="old-price" style="margin-left: auto;">
                    <span class="" style="">Price : ₹ 29,999/<span style="">+GST </span></span>
                    <div class="cross-img-div">
                      <!-- <img src="../assets/Group_45140.svg" alt="cross-img" /> -->
                    </div>
                  </div>
                  <h5 class="highlight mb-0" @click="
                    freeforever(
                      'https://play.google.com/store/apps/details?id=com.redrestro&hl=en&gl=US'
                    )
                    ">
                    Price: ₹ 24,999/<span class="fs-5">+GST (For Lifetime)</span>
                  </h5>
                  <span class="" style="
            font-size: 1.2rem;
            text-decoration: none;
            font-weight: 600;
            color: red;
          ">

                  </span>
                </div>
              </div>
            </div>

            <!-- Image Side -->
            <div class="col-md-3 col-sm-12 p-0 h-100 d-flex align-items-start justify-content-start"
              style="padding-top: 2.2rem !important;">
              <img class="img-fluid custom-img-width" style="cursor: pointer;"
                src="./../assets/Consltunt panel_web_design/Group 2139.png" alt="App Image" @click="
                  freeforever(
                    'https://play.google.com/store/apps/details?id=com.redrestro&hl=en&gl=US'
                  )
                  " />
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12  mb-5 " id="right-product">
          <div class="row h-100" style="display: flex; align-items: center; justify-content: start">
            <!-- Image Side -->
            <div class="col-md-3 col-sm-12 p-0 h-100 d-flex align-items-start justify-content-end"
              style="padding-top: 2.2rem !important;">
              <img style="float: right; cursor: pointer;" class="img-fluid custom-img-width-1"
                src="./../assets/Consltunt panel_web_design/Group 2133.png" alt="App Image" @click="
                  freeforever(
                    'https://play.google.com/store/apps/details?id=com.ibhrms&hl=en&gl=US'
                  )
                  " />
            </div>
            <!-- Content Side -->
            <div class="col-md-8 col-sm-12 p-4 d-flex h-100" style="text-align: left">
              <div class="content-side" @click="
                freeforever(
                  'https://play.google.com/store/apps/details?id=com.ibhrms&hl=en&gl=US'
                )
                ">
                <h4 class="title">IBHRMS</h4>
                <p class="description d-flex text-right">
                  InfinityBrains Human Resource Management System (IBHRMS) is a
                  comprehensive and specialized software solution designed to
                  address the diverse and intricate needs of modern businesses.
                  This system seamlessly integrates various HR functions,
                  business processes to create a cohesive platform.
                </p>

                <div class="price-div">
                  <div class="old-price">
                    <span class="" style="">Price : ₹ 29,999/<span style="">+GST </span></span>
                    <div class="cross-img-div">
                      <!-- <img src="../assets/Group_45140.svg" alt="cross-img" /> -->
                    </div>
                  </div>

                  <h5 class="highlight mb-0" @click="
                    freeforever(
                      'https://play.google.com/store/apps/details?id=com.ibhrms&hl=en&gl=US'
                    )
                    ">
                    Price: ₹ 24,999/<span class="fs-5">+GST (For Lifetime)</span>
                  </h5>
                  <span class="" style="
                    font-size: 1.2rem;
                    text-decoration: none;
                    font-weight: 600;
                    color: red;
                  ">

                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- end -->
        <!-- 2nd row -->
        <div class="col-md-6 col-sm-12  mb-5  " id="left-product">
          <div class="row h-100" style="display: flex; align-items: center; justify-content: end">
            <!-- Content Side -->
            <div class="col-md-8 col-sm-12 p-4 d-flex text-right h-100" style="text-align: right">
              <div class="content-side" @click="
                freeforever(
                  'https://play.google.com/store/apps/details?id=com.ib.ibsalesforce&hl=en&gl=US'
                )
                ">
                <h4 class="title">IB-SALESFORCS</h4>
                <p class="description d-flex text-right">
                  IbSalesforce is an application designed to assist companies in
                  tracking and managing client relationships. It enables
                  employees to input client data and provides managers with
                  tools to supervise and analyze the information. Ultimately,
                  IbSalesforce aims to deliver a centralized and organized
                  system.
                </p>

                <div class="price-div">
                  <div class="old-price" style="margin-left: auto;">
                    <span class="" style="">Price : ₹ 29,999/<span style="">+Gst
                      </span></span>
                    <div class="cross-img-div">
                      <!-- <img src="../assets/Group_45140.svg" alt="cross-img" /> -->
                    </div>
                  </div>
                  <h5 class="highlight mb-0" @click="
                    freeforever(
                      'https://play.google.com/store/apps/details?id=com.ib.ibsalesforce&hl=en&gl=US'
                    )
                    ">
                    Price: ₹ 24,999/<span class="fs-5">+GST (For Lifetime)</span>
                  </h5>
                  <span class="" style="
                    font-size: 1.2rem;
                    text-decoration: none;
                    font-weight: 600;
                    color: red;
                  ">

                  </span>
                </div>
              </div>
            </div>

            <!-- Image Side -->
            <div class="col-md-3 col-sm-12 p-0 h-100 d-flex align-items-start justify-content-start"
              style="padding-top: 2.2rem !important;">
              <img class="img-fluid custom-img-width" style="cursor: pointer;"
                src="./../assets/Consltunt panel_web_design/Group 2135.png" alt="App Image" @click="
                  freeforever(
                    'https://play.google.com/store/apps/details?id=com.ib.ibsalesforce&hl=en&gl=US'
                  )
                  " />
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12  mb-5 " id="right-product">
          <div class="row h-100" style="display: flex; align-items: center; justify-content: start">
            <!-- Image Side -->
            <div class="col-md-3 col-sm-12 p-0 h-100 d-flex align-items-start justify-content-end"
              style="padding-top: 2.2rem !important;">
              <img style="float: right;cursor: pointer;" class="img-fluid custom-img-width"
                src="./../assets/Consltunt panel_web_design/Group 2134.png" alt="App Image"
                @click="freeforever('https://infinitybrains.com/4')" />
            </div>
            <!-- Content Side -->
            <div class="col-md-8 col-sm-12 p-4 d-flex h-100" style="text-align: left">
              <div class="content-side" @click="freeforever('https://infinitybrains.com/4')">
                <h4 class="title">AI-CREATIVES</h4>
                <p class="description d-flex text-right">
                  Unleash your creativity with Festival Creatives! Our product
                  offers a wide variety of designs and templates for all your
                  festive needs. From birthday celebrations to national
                  holidays, we've got you covered. Say goodbye to the stress of
                  creating your own designs and let us take care of it for you.
                </p>
                <h5 class="highlight mb-0" @click="freeforever('https://infinitybrains.com/4')">
                  Free Forever
                </h5>
              </div>
            </div>
          </div>
        </div>

        <!-- end -->
        <!-- 3rd row -->
        <div class="col-md-6 col-sm-12   mb-5 " id="left-product">
          <div class="row h-100" style="display: flex; align-items: center; justify-content: end">
            <!-- Content Side -->
            <div class="col-md-8 col-sm-12 p-4 d-flex text-right h-100" style="text-align: right">
              <div class="content-side" @click="
                freeforever(
                  'https://play.google.com/store/apps/details?id=com.ibdelight&hl=en&gl=US'
                )
                ">
                <h4 class="title">DELIGHT</h4>
                <p class="description d-flex text-right">
                  Delight, established on July 6, 2023, signifies a
                  groundbreaking advancement in the telecommunications industry.
                  This specialized application is meticulously crafted to
                  transcend conventional boundaries, providing a comprehensive
                  solution tailored exclusively for the telecom sector while its
                  benefits beyond.
                </p>

                <div class="price-div">
                  <div class="old-price" style="margin-left: auto;">
                    <span class="" style="">Price : ₹ 29,999/<span style="">+GST </span></span>
                    <div class="cross-img-div">
                      <!-- <img src="../assets/Group_45140.svg" alt="cross-img" /> -->
                    </div>
                  </div>

                  <h5 class="highlight mb-0" @click="
                    freeforever(
                      'https://play.google.com/store/apps/details?id=com.ibdelight&hl=en&gl=US'
                    )
                    ">
                    Price: ₹24,999/<span class="fs-5">+GST (For Lifetime)</span>
                  </h5>
                  <span class="" style="
                font-size: 1.2rem;
                text-decoration: none;
                font-weight: 600;
                color: red;
              ">

                  </span>
                </div>
              </div>
            </div>

            <!-- Image Side -->
            <div class="col-md-3 col-sm-12 p-0 h-100 d-flex align-items-start justify-content-start"
              style="padding-top: 2.2rem !important;">
              <img class="img-fluid custom-img-width" style="cursor: pointer;"
                src="./../assets/Consltunt panel_web_design/Group 2136.png" alt="App Image" @click="
                  freeforever(
                    'https://play.google.com/store/apps/details?id=com.ibdelight&hl=en&gl=US'
                  )
                  " />
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12  mb-5 " id="right-product">
          <div class="row h-100" style="display: flex; align-items: center; justify-content: start">
            <!-- Image Side -->
            <div class="col-md-3 col-sm-12 p-0 h-100 d-flex align-items-start justify-content-end"
              style="padding-top: 2.2rem !important;">
              <img style="float: right;cursor: pointer;" class="img-fluid custom-img-width"
                src="./../assets/Consltunt panel_web_design/shree_mahalakshmi_krupa_bg.png" alt="App Image" @click="
                  freeforever(
                    'https://play.google.com/store/apps/details?id=com.ib.shree_mahalakshmi_krupa&hl=en&gl=US'
                  )
                  " />
            </div>
            <!-- Content Side -->
            <div class="col-md-8 col-sm-12 p-4 d-flex h-100" style="text-align: left">
              <div class="content-side" @click="
                freeforever(
                  'https://play.google.com/store/apps/details?id=com.ib.shree_mahalakshmi_krupa&hl=en&gl=US'
                )
                ">
                <h4 class="title">Shree Mahalakshmi Krupa</h4>
                <p class="description d-flex text-right">
                  Shree Mahalakshmi Krupa is an advanced cloud-based customer
                  relationship management (CRM) application designed to enhance
                  businesses' customer service and operational efficiency. With
                  Shree Mahalakshmi Krupa, businesses can seamlessly track
                  customer interactions across all channels, automate tasks for
                  a more streamlined and effective workflow.
                </p>

                <div class="price-div">
                  <div class="old-price">
                    <span class="" style="">Price : ₹ 29,999/<span style="">+GST </span></span>
                    <div class="cross-img-div">
                      <!-- <img src="../assets/Group_45140.svg" alt="cross-img" /> -->
                    </div>
                  </div>
                  <h5 class="highlight mb-0" @click="
                    freeforever(
                      'https://play.google.com/store/apps/details?id=com.ib.shree_mahalakshmi_krupa&hl=en&gl=US'
                    )
                    ">
                    Price: ₹ 24,999/<span class="fs-5">+GST (For Lifetime)</span>
                  </h5>
                  <span class="" style="
                font-size: 1.2rem;
                text-decoration: none;
                font-weight: 600;
                color: red;
              ">

                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>



        <!-- end -->
      </div>
    </section>

    <section class="smallWindow" style="margin-top: 100px; padding: 0px 10px; margin-bottom: 50px">
      <div class="row ">
        <!-- 1st row -->
        <div class=" col-sm-12  mb-5  " id="left-product">
          <div class="row " style="display: flex; align-items: center; justify-content: center">
            <!-- Image Side -->
            <div class=" col-sm-12 p-0 " style="display: flex; align-items: center; justify-content: center;">
              <img style="cursor: pointer;" class="img-fluid"
                src="./../assets/Consltunt panel_web_design/Group 2132.png" alt="App Image" @click="
                  freeforever(
                    'https://infinitybrains.com/10M_website/categories'
                  )
                  " />
            </div>
            <!-- Content Side -->
            <div class="col-md-6 col-sm-12 p-4 d-flex text-right w-100" style="text-align: center">
              <div class="content-side" @click="
                freeforever(
                  'https://infinitybrains.com/10M_website/categories'
                )
                ">
                <h4 class="title">APP IN 10 MINUTES</h4>
                <p class="description d-flex text-right">
                  The title "App in 10 Minutes" suggests a rapid and efficient
                  approach to creating a mobile application. This concept
                  implies that the process of developing the app is streamlined
                  and achievable within a short time frame, specifically ten
                  minutes. The description for this title could highlight the
                  following key points.
                </p>
                <h5 class="highlight" @click="
                  freeforever(
                    'https://infinitybrains.com/10M_website/categories'
                  )
                  ">
                  Free Forever
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div class=" col-sm-12  mb-5  " id="right-product">
          <div class="row " style="display: flex; align-items: center; justify-content: center">
            <!-- Image Side -->
            <div class=" col-sm-12 p-0 " style="display: flex; align-items: center; justify-content: center;">
              <img style="cursor: pointer;" class="img-fluid"
                src="./../assets/Consltunt panel_web_design/Group 2133.png" alt="App Image" @click="
                  freeforever(
                    'https://play.google.com/store/apps/details?id=com.ibhrms&hl=en&gl=US'
                  )
                  " />
            </div>
            <!-- Content Side -->
            <div class="col-md-6 col-sm-12 p-4 d-flex text-right w-100" style="text-align: center">
              <div class="content-side" @click="
                freeforever(
                  'https://play.google.com/store/apps/details?id=com.ibhrms&hl=en&gl=US'
                )
                ">
                <h4 class="title">IBHRMS</h4>
                <p class="description d-flex text-right">
                  InfinityBrains Human Resource Management System (IBHRMS) is a
                  comprehensive and specialized software solution designed to
                  address the diverse and intricate needs of modern businesses.
                  This system seamlessly integrates various HR functions,
                  business processes to create a cohesive platform.
                </p>

                <div class="price-div">
                  <div class="old-price" style="margin-left: auto; margin-right: auto;">
                    <span class="" style="">Price : ₹ 29,999/+Gst<span style="">
                      </span></span>
                    <div class="cross-img-div">
                      <!-- <img src="../assets/Group_45140.svg" alt="cross-img" /> -->
                    </div>
                  </div>
                  <h5 class="highlight" @click="
                    freeforever(
                      'https://play.google.com/store/apps/details?id=com.ibhrms&hl=en&gl=US'
                    )
                    ">
                    Price: ₹ 24,999/<span class="fs-5">+GST (For Lifetime)</span>
                  </h5>
                  <span class="" style="
                font-size: 1.2rem;
                text-decoration: none;
                font-weight: 600;
                color: red;
              ">

                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class=" col-sm-12  mb-5  " id="left-product">
          <div class="row " style="display: flex; align-items: center; justify-content: center">
            <!-- Image Side -->
            <div class=" col-sm-12 p-0 " style="display: flex; align-items: center; justify-content: center;">
              <img style="cursor: pointer;" class="img-fluid"
                src="./../assets/Consltunt panel_web_design/Group 2135.png" alt="App Image" @click="
                  freeforever(
                    'https://play.google.com/store/apps/details?id=com.ib.ibsalesforce&hl=en&gl=US'
                  )
                  " />
            </div>
            <!-- Content Side -->
            <div class="col-md-6 col-sm-12 p-4 d-flex text-right w-100" style="text-align: center">
              <div class="content-side" @click="
                freeforever(
                  'https://play.google.com/store/apps/details?id=com.ib.ibsalesforce&hl=en&gl=US'
                )
                ">
                <h4 class="title">IB-SALESFORCS</h4>
                <p class="description d-flex text-right">
                  IbSalesforce is an application designed to assist companies in
                  tracking and managing client relationships. It enables
                  employees to input client data and provides managers with
                  tools to supervise and analyze the information. Ultimately,
                  IbSalesforce aims to deliver a centralized and organized
                  system.
                </p>
                <div class="price-div">
                  <div class="old-price" style="margin-left: auto; margin-right: auto;">
                    <span class="" style="">Price : ₹ 29,999/<span style="">
                      </span></span>
                    <div class="cross-img-div">
                      <!-- <img src="../assets/Group_45140.svg" alt="cross-img" /> -->
                    </div>
                  </div>
                  <h5 class="highlight" @click="
                    freeforever(
                      'https://play.google.com/store/apps/details?id=com.ib.ibsalesforce&hl=en&gl=US'
                    )
                    ">
                    Price: ₹ 24,999/<span class="fs-5">+GST (For Lifetime)</span>
                  </h5>
                  <span class="" style="
            font-size: 1.2rem;
            text-decoration: none;
            font-weight: 600;
            color: red;
          ">

                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class=" col-sm-12  mb-5  " id="right-product">
          <div class="row " style="display: flex; align-items: center; justify-content: center">
            <!-- Image Side -->
            <div class=" col-sm-12 p-0 " style="display: flex; align-items: center; justify-content: center;">
              <img style="cursor: pointer;" class="img-fluid"
                src="./../assets/Consltunt panel_web_design/Group 2134.png" alt="App Image"
                @click="freeforever('https://infinitybrains.com/4')" />
            </div>
            <!-- Content Side -->
            <div class="col-md-6 col-sm-12 p-4 d-flex text-right w-100" style="text-align: center">
              <div class="content-side" @click="freeforever('https://infinitybrains.com/4')">
                <h4 class="title">AI-CREATIVES</h4>
                <p class="description d-flex text-right">
                  Unleash your creativity with Festival Creatives! Our product
                  offers a wide variety of designs and templates for all your
                  festive needs. From birthday celebrations to national
                  holidays, we've got you covered. Say goodbye to the stress of
                  creating your own designs and let us take care of it for you.
                </p>
                <h5 class="highlight" @click="freeforever('https://infinitybrains.com/4')">
                  Free Forever
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div class=" col-sm-12  mb-5  " id="left-product">
          <div class="row " style="display: flex; align-items: center; justify-content: center">
            <!-- Image Side -->
            <div class=" col-sm-12 p-0 " style="display: flex; align-items: center; justify-content: center;">
              <img style="cursor: pointer;" class="img-fluid"
                src="./../assets/Consltunt panel_web_design/Group 2136.png" alt="App Image" @click="
                  freeforever(
                    `https://play.google.com/store/apps/details?id=com.ibdelight&hl=en&gl=US`
                  )
                  " />
            </div>
            <!-- Content Side -->
            <div class="col-md-6 col-sm-12 p-4 d-flex text-right w-100" style="text-align: center">
              <div class="content-si,de" @click="
                freeforever(
                  `https://play.google.com/store/apps/details?id=com.ibdelight&hl=en&gl=US`
                )
                ">
                <h4 class="title">DELIGHT</h4>
                <p class="description d-flex text-right">
                  Delight, established on July 6, 2023, signifies a
                  groundbreaking advancement in the telecommunications industry.
                  This specialized application is meticulously crafted to
                  transcend conventional boundaries, providing a comprehensive
                  solution tailored exclusively for the telecom sector while its
                  benefits beyond.
                </p>
                <div class="price-div">
                  <div class="old-price" style="margin-left: auto; margin-right: auto;">
                    <span class="" style="">Price : ₹ 29,999/<span style="">+GST </span></span>
                    <div class="cross-img-div">
                      <!-- <img src="../assets/Group_45140.svg" alt="cross-img" /> -->
                    </div>
                  </div>
                  <h5 class="highlight" @click="
                    freeforever(
                      `https://play.google.com/store/apps/details?id=com.ibdelight&hl=en&gl=US`
                    )
                    ">
                    Price: ₹ 24,999/<span class="fs-5">+GST (For Lifetime)</span>
                  </h5>
                  <span class="" style="
            font-size: 1.2rem;
            text-decoration: none;
            font-weight: 600;
            color: red;
          ">

                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class=" col-sm-12  mb-5  " id="right-product">
          <div class="row " style="display: flex; align-items: center; justify-content: center">
            <!-- Image Side -->
            <div class=" col-sm-12 p-0 " style="display: flex; align-items: center; justify-content: center;">
              <img style="cursor: pointer;" class="img-fluid custom-img-width"
                src="./../assets/Consltunt panel_web_design/shree_mahalakshmi_krupa_bg.png" alt="App Image" @click="
                  freeforever(
                    'https://play.google.com/store/apps/details?id=com.ib.shree_mahalakshmi_krupa&hl=en&gl=US'
                  )
                  " />
            </div>
            <!-- Content Side -->
            <div class="col-md-6 col-sm-12 p-4 d-flex text-right w-100" style="text-align: center">
              <div class="content-side" @click="
                freeforever(
                  'https://play.google.com/store/apps/details?id=com.ib.shree_mahalakshmi_krupa&hl=en&gl=US'
                )
                ">
                <h4 class="title">Shree Mahalakshmi Krupa</h4>
                <p class="description d-flex text-right">
                  Shree Mahalakshmi Krupa is an advanced cloud-based customer
                  relationship management (CRM) application designed to enhance
                  businesses' customer service and operational efficiency. With
                  Shree Mahalakshmi Krupa, businesses can seamlessly track
                  customer interactions across all channels, automate tasks for
                  a more streamlined and effective workflow.
                </p>

                <div class="price-div">
                  <div class="old-price" style="margin-left: auto; margin-right: auto;">
                    <span class="" style="">Price : ₹ 29,999/<span style="">+GST </span></span>
                    <div class="cross-img-div">
                      <!-- <img src="../assets/Group_45140.svg" alt="cross-img" /> -->
                    </div>
                  </div>
                  <h5 class="highlight" @click="
                    freeforever(
                      'https://play.google.com/store/apps/details?id=com.ib.shree_mahalakshmi_krupa&hl=en&gl=US'
                    )
                    ">
                    Price: ₹ 24,999/<span class="fs-5">+GST (For Lifetime)</span>
                  </h5>
                  <span class="" style="
        font-size: 1.2rem;
        text-decoration: none;
        font-weight: 600;
        color: red;
      ">

                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class=" col-sm-12  mb-5  " id="left-product">
          <div class="row " style="display: flex; align-items: center; justify-content: center">
            <!-- Image Side -->
            <div class=" col-sm-12 p-0 " style="display: flex; align-items: center; justify-content: center;">
              <img style="cursor: pointer;" class="img-fluid"
                src="./../assets/Consltunt panel_web_design/Group 2139.png" alt="App Image" @click="
                  freeforever(
                    'https://play.google.com/store/apps/details?id=com.redrestro&hl=en&gl=US'
                  )
                  " />
            </div>
            <!-- Content Side -->
            <div class="col-md-6 col-sm-12 p-4 d-flex text-right w-100" style="text-align: center">
              <div class="content-side" @click="
                freeforever(
                  'https://play.google.com/store/apps/details?id=com.redrestro&hl=en&gl=US'
                )
                ">
                <h4 class="title">REDRESTRO</h4>
                <p class="description d-flex text-right">
                  Red Restro is a revolutionary Customer Relationship Management
                  (CRM) application meticulously designed to cater to the
                  diverse needs of businesses in today's dynamic market
                  landscape. At its core, Red Restro serves as a comprehensive
                  solution aimed at optimizing business processes and enhancing
                  service.
                </p>
                <div class="price-div">
                  <div class="old-price" style="margin-left: auto; margin-right: auto;">
                    <span class="" style="">Price : ₹ 29,999/<span style="">+GST </span></span>
                    <div class="cross-img-div">
                      <!-- <img src="../assets/Group_45140.svg" alt="cross-img" /> -->
                    </div>
                  </div>
                  <h5 class="highlight" @click="
                    freeforever(
                      'https://play.google.com/store/apps/details?id=com.redrestro&hl=en&gl=US'
                    )
                    ">
                    Price: ₹ 24,999/<span class="fs-5">+GST (For Lifetime)</span>
                  </h5>
                  <span class="" style="
    font-size: 1.2rem;
    text-decoration: none;
    font-weight: 600;
    color: red;
  ">

                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class=" col-sm-12  mb-5  " id="right-product">
          <div class="row " style="display: flex; align-items: center; justify-content: center">
            <!-- Image Side -->
            <div class=" col-sm-12 p-0 " style="display: flex; align-items: center; justify-content: center;">
              <img style="cursor: pointer;" class="img-fluid"
                src="./../assets/Consltunt panel_web_design/Group 2138.png" alt="App Image" @click="
                  freeforever(
                    'https://www.infinitybrains.com/10M_website/categories'
                  )
                  " />
            </div>
            <!-- Content Side -->
            <div class="col-md-6 col-sm-12 p-4 d-flex text-right w-100" style="text-align: center">
              <div class="content-side" @click="
                freeforever(
                  'https://www.infinitybrains.com/10M_website/categories'
                )
                ">
                <h4 class="title">WEB IN 10 MINUTES</h4>
                <p class="description d-flex text-right">
                  The title "WEB in 10 Minutes" suggests a rapid and efficient
                  approach to creating a website app. This concept implies that
                  the process of developing the web is streamlined and
                  achievable within a short time frame, specifically ten
                  minutes. The description for this title could highlight the
                  following key points.
                </p>
                <h5 class="highlight" @click="
                  freeforever(
                    'https://www.infinitybrains.com/10M_website/categories'
                  )
                  ">
                  Free Forever
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section style="margin-top: 100px; text-align: center">
      <h2
        style="
          font-size: 2.5rem;
          line-height: normal;
          font-weight: 500;
          margin-bottom: 0;
          color: black;
        "
      >
        Checkout Out Other <span style="color: rgb(66, 66, 155)">Brands</span>
      </h2>
      <h3
        style="
          font-size: 1.5rem;
          font-weight: 600;
          color: rgb(119, 117, 117);
          top: 0;
          margin: 0;
        "
      >
        Discover More Software Solution To enhance Your Digital Experience
      </h3>
    </section> -->

    <!-- <section>
      <div class="banner-brand ">
        <img
          src="./../assets/Consltunt panel_web_design/Group 44430.png"
          alt=""
        />
      </div>
    </section> -->

    <New_Pop_Up_Comp v-if="showModal" :redirected_link="redirect_link" :modal_visible="showModal"
      @closeModal="handleModalClick" />

    <Footer />
  </div>
</template>

<script>
import NavBarComp from "../components/NavBarComp/NavBarComp.vue";
import Footer from "../components/Footer/Footer.vue";

import AboutInfinityBrainsVue from "../components/AboutInfinityBrains.vue";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";

import New_Pop_Up_Comp from "../components/New_Pop_Up/New_Pop_Up_Comp.vue";

export default {
  data() {
    return {
      showModal: false,
      redirect_link: "https://infinitybrains.com/paymentForm/4"
    };
  },
  components: {
    New_Pop_Up_Comp,
    NavBarComp,
    Footer,
    AboutInfinityBrainsVue
  },
  computed: {
    isVisible() {
      return window.innerWidth > 900;
    }
  },
  mounted() {
    localStorage.removeItem("signup");

    if (localStorage.getItem("vue-authenticate.vueauth_access_token")) {
      this.$router.push(`/candidate`);
    }

    setTimeout(() => {
      this.showModal = true;
    }, 950);

    const observer_left = new IntersectionObserver(entries => {
      entries.forEach(element => {
        if (element.isIntersecting) {
          element.target.classList.add("animated-anim-left");
          observer_left.unobserve(element.target);
        }
      });
    });

    this.$nextTick(() => {
      document
        .querySelectorAll("#left-product")
        .forEach(div => observer_left.observe(div));
    });

    const observer_right = new IntersectionObserver(entries => {
      entries.forEach(element => {
        if (element.intersectionRatio > 0) {
          element.target.classList.add("animated-anim-right");
          observer_right.unobserve(element.target);
        }
      });
    });

    this.$nextTick(() => {
      document
        .querySelectorAll("#right-product")
        .forEach(div => observer_right.observe(div));
    });
  },
  methods: {
    checkScreenSize() {
      // Update is_screen_large based on the screen width
      this.is_screen_large = window.innerWidth > 900;
    },
    signup() {
      this.$router.push("/login");
    },
    login() {
      // Add your signup logic here
      this.$router.push("/login");
      localStorage.setItem("signup", true);
    },
    freeforever(url) {
      window.location.href = url;
    }
  }
};
</script>

<style>
body {
  background-color: #ffffff !important;
  color: #212529;
}
</style>
<style scoped>
.custom-img-width {
  width: 10rem;
}

.animated-anim-left {
  animation: fadeInLeft;
  animation-duration: 1.2s;
}

.animated-anim-right {
  animation: fadeInRight;
  animation-duration: 1.2s;
}

.custom-img-width-1 {
  width: 11rem;
}

.first-nav-div {
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hover-bg-color {
  background-color: white !important;
}

.hover-bg-color:hover {
  background-color: #0c2d61 !important;
  color: #ffffff !important;
}

nav {
  background-color: white;
  color: #0c2d61;
  padding: 15px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.first-nav-div {
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  cursor: pointer;
}

@media only screen and (max-width: 1280px) {
  .first-nav-div {
    width: fit-content;
    flex-direction: column;
    align-items: flex-start;
  }
}

.logo {
  margin-top: -5px;
}

.company-name {
  font-size: 18px;
  font-weight: bold;
}

.login-signup {
  gap: 10px;
}

.login-signup button {
  padding: 10px 25px;
  margin-left: 10px;
  font-size: 14px;
  background: #0c2d61;
  border: none;
  color: white;
  font-weight: 600;
  box-shadow: 0p 10px 2px gray;
  /* -webkit-animation-fill-mode: ; */
  border-radius: 7px;
}

.banner-img {
  width: 100%;
  height: 700px;
  object-fit: cover;
  margin-bottom: 70px;
}

.introduction {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.intro-title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 30px;
}

.intro-title h3 {
  font-size: 2rem;
  color: rgba(0, 0, 0, 0.767);
  font-weight: 500;
  line-height: 1.8rem;
  margin: 0;
}

.intro-title p {
  font-size: 1.5rem;
  color: rgb(80, 80, 80);
  font-weight: 500;
  line-height: 1.8rem;
  margin-top: 6px;
}

.banner-signup {
  padding: 50px 0px;
  margin-top: 130px;
  background-image: url("./../assets/Consltunt\ panel_web_design/Rectangle 2560.png");
  /* background-image: url('./../assets/images/avatar.jpg'); */
}

.banner-brand {
  display: flex;
  flex-wrap: wrap;
  margin: 100px 0px;
  padding: 0% 5%;
  justify-content: center;
}

.banner-feature {
  display: flex;
  flex-wrap: wrap;
  margin: 130px 0px;
  padding: 0% 5%;
  justify-content: center;
}

.f {
  flex: auto;
  text-align: center;
  justify-content: center;
  padding: 40px;
  /* margin: 10px; */
  background-size: cover;
  min-width: 400px;
  max-width: 400px;
  max-height: 200px;
  position: relative;
}

.f img {
  max-width: 100%;
  height: auto;
  margin-bottom: 12px;
}

.f h3 {
  position: relative;
  bottom: 10px;
  left: 0;
  right: 0;

  color: black;
  /* Adjust text color */
}

/* Add background images for each span */
.f:nth-child(1) {
  background-image: url("./../assets/Consltunt panel_web_design/Rectangle 3656.png");
}

.f:nth-child(2) {
  background-image: url("./../assets/Consltunt panel_web_design/Rectangle 3656.png");
}

.f:nth-child(3) {
  background-image: url("./../assets/Consltunt panel_web_design/Rectangle 3656.png");
}

.f:nth-child(4) {
  background-image: url("./../assets/Consltunt panel_web_design/Rectangle 3656.png");
}

/* Media Query for Responsive Design */

.banner-company_dis {
  color: black;

  margin: 130px 0px;
  padding: 0% 150px;
}

.banner-company_dis h1 {
  font-size: 2rem;
  color: rgb(37, 37, 37);
  font-weight: 500;
  margin-bottom: 10px;
}

.banner-company_dis h4 {
  font-size: 1.5rem;
  color: rgb(37, 37, 37);
  font-weight: 400;
  margin-bottom: 20px;
}

.title {
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 20px;
  color: black;
  cursor: pointer;
}

.description {
  font-size: 1.2rem;
  color: rgb(41, 41, 41);
  font-weight: 500;
  cursor: pointer;
  opacity: 0.8;
}

.price-div {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}

.old-price {
  width: fit-content;
  height: fit-content;
  position: relative;
  margin-top: 0.2rem;
  margin-bottom: 0.8rem;
  padding: 0 0.6rem;
}

.old-price span {
  color: #012a71;
  font-weight: bold;
  font-size: 1.5rem;
  margin: 0.5rem 0;
}

.old-price span span {
  font-size: 1rem;
}

@media screen and (max-width: 405px) {
  .old-price {
    padding: 0 0.2rem;
  }

  .old-price span {
    font-size: 0.9rem !important;
  }

  .old-price span span {
    font-size: 0.8rem !important;
  }
}

@media screen and (min-width: 900px) and (max-width: 1119px) {
  .old-price {
    padding: 0 0.2rem;
  }

  .old-price span {
    font-size: 1rem !important;
  }

  .old-price span span {
    font-size: 0.8rem !important;
  }
}

@media screen and (min-width: 1120px) and (max-width: 1224px) {
  .old-price span {
    font-size: 1.2rem !important;
  }

  .old-price span span {
    font-size: 1rem !important;
  }
}

.old-price span {
  line-height: 1em;
  color: #446cb1;
}

.cross-img-div {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0%;
  border-bottom: 2px solid red;
}

.cross-img-div img {
  width: 100%;
  height: inherit;
}

.highlight {
  font-size: 2rem;
  color: #0c2d61;
  font-weight: bold;
  line-height: 1em;
  cursor: pointer;
}

footer {
  background-color: #0c2d61;
  color: white;
  padding: 20px;
  /* text-align: left; */
  justify-content: center;
}

.footer-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  /* gap: 16rem; */
  align-items: center;
}

.footer-section {
  max-width: 20%;
}

.footer-heading {
  text-align: left;
  margin-bottom: 20px;
  text-shadow: 0px 1px 0px;
  color: white;
}

.footer-copyright {
  margin-top: 20px;
  text-align: center;
}

i {
  background-color: white;
  border-radius: 50%;
  color: #0c2d61;
  padding: 2%;
  margin-right: 15px;
}

.smallWindow {
  display: none;
}

.largeWindow {
  display: block;
}

@media only screen and (max-width: 900px) {
  /* .logo {
    width: 50% !important;
    margin-top: 107% !important;
    position: absolute;
  }*/

  .first-nav-div {
    align-items: center !important;
    margin-bottom: 0.5rem;
  }

  .smallWindow {
    display: block;
  }

  .largeWindow {
    display: none;
  }

  .footer-section {
    display: block;
    max-width: 100%;
  }

  .footer-content {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    /* gap: 16rem; */
    align-items: center;
  }

  .footer-heading {
    text-align: left;
  }

  nav {
    flex-direction: column;
    align-items: center;
  }

  .banner-img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }

  .login-signup {
    margin-top: 10px;
  }

  .img-des {
    display: flex;
    flex-direction: column;
  }

  .discription-long h2 {
    font-size: 15px !important;
  }

  .banner-company_dis {
    color: black;

    margin: 130px 0px;
    padding: 0% 10px;
  }

  .smallImg {
    display: none;
  }

  .marquee {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    font-size: 10px;
    padding: 20px;
    color: #ffffff;
    animation: marqueeRightToLeft 10s linear infinite;
  }
}

.marquee-container {
  display: flex;
  overflow: hidden;
  background-color: #0c2d61;

  margin: 10px;
}

.marquee {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  font-size: 1.3rem;
  padding: 20px;
  color: #ffffff;
  animation: marqueeRightToLeft 10s linear infinite;
}

.marquee:nth-child(odd) {}

.marquee:nth-child(even) {}

@keyframes marqueeRightToLeft {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}
</style>
